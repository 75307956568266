import React from "react"
import {StaticImage} from "gatsby-plugin-image"
import App from '../../components/app'
import BannerLayout from '../../components/banner-layout'
// import BannerLayout from '../../components/banner-layout'
import Encomendar from '../../components/encomendar'

import ogImage from "../../assets/images/pao-de-queijo-01.jpg"

const title = "Pão de Queijo da Lini"

const description="Pão de Queijo feito na hora é um lanche perfeito. Acompanha um café ou aproveita os Pãozinhos de Queijo com amigos e um vinho verde fresco e leve. Faço os tradicionais com queijo só ou com tomate seco ou com orégão. Bom apetite!"

const BannerImage = <StaticImage src="../../assets/images/pao-de-queijo-01.jpg" alt={title} className="uk-height-medium uk-animation-fade"/>

export default function PaoDeQueijo({location}) {
  return <App location={location} title={title} description={description} image={ogImage}>
    <BannerLayout title={title} bannerImage={BannerImage}>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <p className="uk-text-lead">{description}</p>
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div data-uk-slideshow="autoplay: true">
          <div class="uk-light uk-position-relative uk-visible-toggle" data-uk-slideshow="autoplay: true">
            <ul class="uk-slideshow-items">
              <li>
                <StaticImage className="uk-animation-fade" src="../../assets/images/pao-de-queijo-tradicional-01.jpg" alt="Pão de Queijo Tradicional" objectFit="contain"/>
              </li>
              <li>
                <StaticImage className="uk-animation-fade" src="../../assets/images/pao-de-queijo-com-tomate-seco-01.jpg" alt="Pão de Queijo com Tomate Seco" objectFit="contain"/>
              </li>
              <li>
                <StaticImage className="uk-animation-fade" src="../../assets/images/pao-de-queijo-com-oregano-01.jpg" alt="Pão de Queijo com Orégano" objectFit="contain"/>
              </li>
            </ul>
            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="./" data-uk-slidenav-previous="data-uk-slidenav-previous" data-uk-slideshow-item="previous">{" "}</a>
            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="./" data-uk-slidenav-next="data-uk-slidenav-next" data-uk-slideshow-item="next">{" "}</a>
          </div>
          <ul class="uk-slideshow-nav uk-dotnav uk-flex-center uk-margin"></ul>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container">
          <table className="uk-table uk-table-middle uk-table-divider uk-table-hover uk-background-default">
            <thead>
              <tr>
                <th>Pão de Queijo</th>
                <th className="uk-text-right">Preço</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>6 Pãezinhos de Queijo grandes tradicionais.</td>
                <td className="uk-text-right uk-text-nowrap">4,90 €</td>
              </tr>
              <tr>
                <td>6 Pãezinhos de Queijo grandes com tomate seco.</td>
                <td className="uk-text-right uk-text-nowrap">4,90 €</td>
              </tr>
              <tr>
                <td>6 Pãezinhos de Queijo grandes com orégão.</td>
                <td className="uk-text-right uk-text-nowrap">4,90 €</td>
              </tr>
              <tr>
                <td>6 Pãezinhos de Queijo grandes. 2 tradicionais, 2 com tomate seco, 2 com orégão.</td>
                <td className="uk-text-right uk-text-nowrap">4,90 €</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <Encomendar/>
        </div>
      </section>

      <section className="uk-section uk-section-small uk-text-small">
        <div className="uk-container">
          <p>Ingredientes: Polvilho de milho, queijo, leite, sal, oleo de girassol. Optional: Tomate seco, orégano.</p>
        </div>
      </section>
    </BannerLayout>
  </App>
}
